import React from 'react';
import Header from '@components/layout/header';
import Main from '@components/layout/main';
import Section from '@components/layout/section';
import EventsSection from '@components/sections/events';
import AboutSection from '@components/sections/about';
import SupportersSection from '@components/sections/supporters';
import NewsSection from '@components/sections/news';
import PressSection from '@components/sections/press';
import BlogSection from '@components/sections/blog';
import Meta from '@components/layout/meta';

function Page() {
    return (
        <>
            <Meta
                title='Bündnis #noIAA'
                description='Climate before profit - sustainable mobility now! Protests drove the International Motor Show (German abbreviation: IAA) away from Frankfurt. As Munich’s alliance, we’re continuing where they left off.'
                type='website'
                path='/'
            />
            <Header
                links={[
                    {title: 'Das Bündnis', id: 'about-us'},
                    {title: 'Veranstaltungen', id: 'events'},
                    {title: 'Unterstützer*innen', id: 'supporters'},
                    {title: 'In der Presse', id: 'press'},
                ]}
            />
            <Main noBottom>
                <Section id='about-us'>
                    <AboutSection variant='english' />
                    <BlogSection />
                </Section>
                <Section id='events' dark>
                    <EventsSection />
                    <NewsSection />
                </Section>
                <Section id='supporters'>
                    <SupportersSection />
                </Section>
                <Section id='press' dark>
                    <PressSection />
                </Section>
            </Main>
        </>
    );
}

export default Page;
